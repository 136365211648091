import {
  SET_FORM_VALUES,
  SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_ERROR,
  CLEAR_SUCCESS_MESSAGE,
  OPEN_REVIEW_MODAL,
  HANDLE_GLOBAL_MODAL,
  SET_FOOTER_NAVIGATION,
  SET_MOBILE_VIEW,
  HANDLE_DRAWER_MENU,
  SET_MACRONUTRIENT_ANSWERS,
  SAVE_MACRONUTRIENT_ANSWERS,
  SAVE_MACRONUTRIENT_ANSWERS_SUCCESS,
  SAVE_MACRONUTRIENT_ANSWERS_ERROR,
  GET_MACRONUTRIENT_ANSWERS,
  GET_MACRONUTRIENT_ANSWERS_SUCCESS,
  GET_MACRONUTRIENT_ANSWERS_ERROR,
  HANDLE_WELCOME_MODAL,
  HANDLE_GENERATE_MEALPLAN_MODAL,
  SET_ADVANCED_MODE_MACROS,
  SAVE_ADVANCED_MODE_MACROS,
  SAVE_ADVANCED_MODE_MACROS_SUCCESS,
  SAVE_ADVANCED_MODE_MACROS_ERROR,
  SET_MEAL_PLAN_ANSWERS,
  SET_MACRO_CALCULATION_RESULTS,
} from './constants';

const initialState = {
  form: { name: '', email: '' },
  sendEmailLoading: false,
  sendEmailError: null,
  successMessage: null,
  isOpen: false,
  review: null,
  globalModalOpen: false,
  globalModalContent: null,
  footerNavigation: [],
  isMobileView: false,
  isDrawerOpen: false,
  macronutrientAnswers: {
    unit: 'imperial',
    gender: '',
    weight: null,
    age: null,
    height: '',
    bodyFatPercentage: 15,
    activityFactor: null,
    bmrFormula: 'mifflin-st-jeor',
    goal: '',
  },
  macroResults: {
    bmr: 0,
    maintenanceCalories: 0,
    macrosForEachGoal: {},
  },
  advancedModeMacros: {
    calories: 2000,
    protein: 175,
    carbs: 175,
    fat: 67,
    proteinPercentage: 35,
    carbPercentage: 30,
    fatPercentage: 35,
  },
  mealPlanAnswers: {
    numberOfMeals: 3,
    dietType: 'Standard',
    largestMeal: 'Distribute equally',
    macroPreference: 'balanced',
    isCustomMacros: false,
    customMacros: { protein: 35, carbs: 35, fat: 30 },
  },
  error: null,
  macronutrientAnswersLoading: false,
  macronutrientAnswersLoaded: false,
  isWelcomeModalVisible: false,
  isGenerateMealPlanModalVisible: false,
};

function global(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_FORM_VALUES:
      return {
        ...state,
        form: { ...state.form, ...payload },
      };
    case SEND_EMAIL:
      return {
        ...state,
        sendEmailLoading: true,
        sendEmailError: null,
        successMessage: null,
      };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        sendEmailLoading: false,
        sendEmailError: null,
        successMessage: action.successMessage,
      };
    case SEND_EMAIL_ERROR:
      return {
        ...state,
        sendEmailLoading: false,
        sendEmailError:
          'An error occurred while sending the email, please try again later.',
        successMessage: null,
      };
    case CLEAR_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessage: null,
      };
    case OPEN_REVIEW_MODAL:
      return {
        ...state,
        isOpen: action.isOpen,
        review: action.review,
      };
    case HANDLE_GLOBAL_MODAL:
      return {
        ...state,
        isGlobalModalOpen: action.isGlobalModalOpen,
        globalModalContent: action.globalModalContent,
      };
    case SET_FOOTER_NAVIGATION:
      return {
        ...state,
        footerNavigation: action.footerNavigation,
      };
    case SET_MOBILE_VIEW:
      return {
        ...state,
        isMobileView: action.isMobileView,
      };
    case HANDLE_DRAWER_MENU:
      return {
        ...state,
        isDrawerOpen: action.isDrawerOpen,
      };
    case SET_MACRONUTRIENT_ANSWERS:
      return {
        ...state,
        macronutrientAnswers: {
          ...state.macronutrientAnswers,
          ...action.macronutrientAnswers,
        },
      };
    case SAVE_MACRONUTRIENT_ANSWERS:
    case GET_MACRONUTRIENT_ANSWERS:
      return {
        ...state,
        error: null,
        macronutrientAnswersLoading: true,
      };
    case SAVE_MACRONUTRIENT_ANSWERS_SUCCESS:
    case GET_MACRONUTRIENT_ANSWERS_SUCCESS:
      return {
        ...state,
        macronutrientAnswers: action.payload,
        macronutrientAnswersLoading: false,
        macronutrientAnswersLoaded: true,
      };
    case SAVE_MACRONUTRIENT_ANSWERS_ERROR:
    case GET_MACRONUTRIENT_ANSWERS_ERROR:
      return {
        ...state,
        error: action.payload,
        macronutrientAnswersLoading: false,
      };
    case HANDLE_WELCOME_MODAL:
      return {
        ...state,
        isWelcomeModalVisible: action.payload,
      };
    case HANDLE_GENERATE_MEALPLAN_MODAL:
      return {
        ...state,
        isGenerateMealPlanModalVisible: action.payload,
      };
    case SET_ADVANCED_MODE_MACROS:
      return {
        ...state,
        advancedModeMacros: action.payload,
      };
    case SAVE_ADVANCED_MODE_MACROS:
      return {
        ...state,
        advancedModeMacros: state.advancedModeMacros,
        advancedModeMacrosLoading: true,
        error: null,
      };
    case SAVE_ADVANCED_MODE_MACROS_SUCCESS:
      return {
        ...state,
        advancedModeMacros: action.payload,
        advancedModeMacrosLoading: false,
        error: null,
      };
    case SAVE_ADVANCED_MODE_MACROS_ERROR:
      return {
        ...state,
        error: null,
        advancedModeMacrosLoading: false,
      };
    case SET_MEAL_PLAN_ANSWERS:
      return {
        ...state,
        mealPlanAnswers: {
          ...state.mealPlanAnswers,
          ...action.mealPlanAnswers,
        },
      };
    case SET_MACRO_CALCULATION_RESULTS:
      return {
        ...state,
        macroResults: action.payload,
      };
    default:
      return state;
  }
}

export default global;
