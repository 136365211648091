import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { capitalize, convertToUserFriendly } from '../../helpers/utils';
import { Button, Modal, Slider } from 'antd';
import { motion, AnimatePresence } from 'framer-motion';
import { toPng } from 'html-to-image';
import { proteins, carbs, fats, greenVegetables } from '../../data/foods';
import { IoIosSwap, IoIosArrowBack, IoIosShuffle } from 'react-icons/io';
import { BsDownload, BsCopy } from 'react-icons/bs';
import { PiShareFatBold } from 'react-icons/pi';
import { generateMealOptions } from '../../helpers/utils';
import './index.css';

export const CustomMealPlan = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const mealPlan = location.state?.mealPlan;
  const dietTypeFromModal = location.state?.dietType;
  const isAdvancedMode = location.state?.isAdvancedMode;
  const isMobileView = useSelector((state) => state.global.isMobileView);
  const mealPlanRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [swapInfo, setSwapInfo] = useState(null);
  const [customMealPlan, setCustomMealPlan] = useState(mealPlan);
  const [dietType, setDietType] = useState(dietTypeFromModal);
  const [paddingTop, setPaddingTop] = useState(0);
  const [highlightedCard, setHighlightedCard] = useState({
    mealIndex: null,
    foodIndex: null,
  });
  const [shoppingListModalOpen, setShoppingListModal] = useState(false);
  const [shoppingSliderValue, setshoppingSliderValue] = useState(5);
  const auth = useSelector((state) => state.auth);
  const [macroClasses, setMacroClasses] = useState({
    calories: '',
    protein: '',
    carbs: '',
    fats: '',
  });
  const prevTotalMacros = useRef({
    calories: 0,
    protein: 0,
    carbs: 0,
    fats: 0,
  });
  const [swapOptions, setSwapOptions] = useState(null);
  const [shuffleOptions, setShuffleOptions] = useState(null);
  const foodRefs = useRef([]);

  useEffect(() => {
    if (mealPlan && proteins && carbs && fats && greenVegetables) {
      const generatedShuffleOptions = mealPlan.mealPlan.map((plan) => ({
        protein: proteins.filter((item) =>
          item.recommendedFor.includes(plan.name)
        ),
        carbs: carbs.filter((item) => item.recommendedFor.includes(plan.name)),
        fats: fats.filter((item) => item.recommendedFor.includes(plan.name)),
        greenVegetables: greenVegetables.filter((item) =>
          item.recommendedFor.includes(plan.name)
        ),
      }));

      // Prepare swap options, grouped by category
      const generatedSwapOptions = {
        proteins: proteins.filter((item) =>
          item.dietType.includes(dietType.toLowerCase())
        ),
        carbs: carbs.filter((item) =>
          item.dietType.includes(dietType.toLowerCase())
        ),
        fats: fats.filter((item) =>
          item.dietType.includes(dietType.toLowerCase())
        ),
        greenVegetables: greenVegetables,
      };

      // Initialize the meal plan
      const updatedMealPlan = mealPlan.mealPlan.map((plan, mealIndex) => {
        const pairedOptions = generateMealOptions(
          generatedShuffleOptions[mealIndex], // Pass the entire replacementOptions object
          plan.name // Pass the meal type
        );

        const selectedOption = pairedOptions[0];
        const vegetableOption = selectedOption.vegetable || {
          name: '',
          servingSize: 0,
          servingType: '',
          macros: { calories: 0, protein: 0, carbs: 0, fats: 0 },
          img: '',
        };

        return {
          ...plan,
          foods: [
            {
              name: selectedOption.protein.name,
              servingSize: selectedOption.protein.servingSize,
              servingType: selectedOption.protein.servingType,
              macros: selectedOption.protein.macros,
              category: 'protein',
              img: selectedOption.protein.img,
            },
            {
              name: selectedOption.carb.name,
              servingSize: selectedOption.carb.servingSize,
              servingType: selectedOption.carb.servingType,
              macros: selectedOption.carb.macros,
              category: 'carbs',
              img: selectedOption.carb.img,
            },
            {
              name: selectedOption.fat.name,
              servingSize: selectedOption.fat.servingSize,
              servingType: selectedOption.fat.servingType,
              macros: selectedOption.fat.macros,
              category: 'fats',
              img: selectedOption.fat.img,
            },
            {
              name: vegetableOption.name,
              servingSize: vegetableOption.servingSize,
              servingType: vegetableOption.servingType,
              macros: vegetableOption.macros,
              category: 'greenVegetables',
              img: vegetableOption.img,
            },
          ],
          macros: {
            calories:
              selectedOption.protein.macros.calories +
              selectedOption.carb.macros.calories +
              selectedOption.fat.macros.calories +
              vegetableOption.macros.calories,
            protein:
              selectedOption.protein.macros.protein +
              selectedOption.carb.macros.protein +
              selectedOption.fat.macros.protein +
              vegetableOption.macros.protein,
            carbs:
              selectedOption.protein.macros.carbs +
              selectedOption.carb.macros.carbs +
              selectedOption.fat.macros.carbs +
              vegetableOption.macros.carbs,
            fats:
              selectedOption.protein.macros.fats +
              selectedOption.carb.macros.fats +
              selectedOption.fat.macros.fats +
              vegetableOption.macros.fats,
          },
        };
      });
      setShuffleOptions(generatedShuffleOptions);
      setSwapOptions(generatedSwapOptions);
      setCustomMealPlan({ ...customMealPlan, mealPlan: updatedMealPlan });
    }
  }, [mealPlan, proteins, carbs, fats, greenVegetables, dietType]);

  const generateShoppingList = ({ mealPlan, days }) => {
    const ingredientTotals = {};

    mealPlan.mealPlan.forEach((meal) => {
      meal.foods.forEach((food) => {
        const { name, servingSize, servingType, category } = food;

        if (!ingredientTotals[category]) {
          ingredientTotals[category] = {};
        }

        if (!ingredientTotals[category][name]) {
          ingredientTotals[category][name] = {
            totalServingSize: 0,
            servingType,
            category,
          };
        }

        ingredientTotals[category][name].totalServingSize += servingSize * days;
      });
    });

    return Object.entries(ingredientTotals).reduce(
      (shoppingList, [category, items]) => {
        shoppingList[category] = Object.entries(items).map(([name, details]) =>
          convertToUserFriendly(
            {
              name,
              servingType: details.servingType,
              category: details.category,
            },
            details.totalServingSize
          )
        );
        return shoppingList;
      },
      {}
    );
  };

  const shareShoppingList = (mealPlan, shoppingSliderValue) => {
    const shoppingList = generateShoppingList({
      mealPlan,
      days: shoppingSliderValue,
    });

    const categoryEmojis = {
      protein: '💪',
      carbs: '🍎',
      fats: '🥑',
      greenVegetables: '🥬',
    };

    const formatCategory = (category) => {
      return category
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) => str.toUpperCase());
    };

    const formattedList = Object.entries(shoppingList)
      .map(([category, items]) => {
        const emoji = categoryEmojis[category] || '🛒';
        const formattedCategory = `${emoji} ${formatCategory(category)}`;
        const formattedItems = items.map((item) => `${item}`).join('\n');
        return `${formattedCategory}\n${formattedItems}`;
      })
      .join('\n\n');

    try {
      const textarea = document.createElement('textarea');
      textarea.value = formattedList;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);

      alert('Shopping list copied to clipboard, paste it wherever you want!');
      setShoppingListModal(false);
    } catch (error) {
      console.error('Error copying shopping list:', error);
      alert('Failed to copy the shopping list. Please try again.');
    }
  };

  const totalMacros = useMemo(() => {
    return (
      customMealPlan.mealPlan?.reduce(
        (totals, plan) => {
          if (!plan || !plan.macros) return totals;
          Object.entries(plan.macros).forEach(([key, value]) => {
            totals[key] = (totals[key] || 0) + value;
          });
          return totals;
        },
        { calories: 0, protein: 0, carbs: 0, fats: 0 }
      ) || { calories: 0, protein: 0, carbs: 0, fats: 0 }
    );
  }, [customMealPlan.mealPlan]);

  // useEffect to detect changes in totalMacros
  useEffect(() => {
    const newClasses = {};

    if (totalMacros.calories !== prevTotalMacros.current.calories) {
      newClasses.calories = 'macro-value-change';
    }
    if (totalMacros.protein !== prevTotalMacros.current.protein) {
      newClasses.protein = 'macro-value-change';
    }
    if (totalMacros.carbs !== prevTotalMacros.current.carbs) {
      newClasses.carbs = 'macro-value-change';
    }
    if (totalMacros.fats !== prevTotalMacros.current.fats) {
      newClasses.fats = 'macro-value-change';
    }

    setMacroClasses(newClasses);

    // Reset animation classes after animation ends
    const timeout = setTimeout(() => {
      setMacroClasses({ calories: '', protein: '', carbs: '', fats: '' });
    }, 500);

    // Update ref with current macros
    prevTotalMacros.current = totalMacros;

    return () => clearTimeout(timeout);
  }, [totalMacros]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setPaddingTop(scrollTop > 50 ? 20 : 0);

      // Scroll to the very top if specific conditions are met
      if (scrollTop < 10 && scrollTop !== 0) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (mealPlan) {
      setCustomMealPlan(mealPlan);
    }
    if (dietTypeFromModal) {
      setDietType(dietTypeFromModal);
    }
  }, [mealPlan]);

  const handleSwapClick = (mealIndex, foodIndex, food, mealType) => {
    if (
      highlightedCard.mealIndex === mealIndex &&
      highlightedCard.foodIndex === foodIndex
    ) {
      setSwapInfo(null);
      setHighlightedCard({ mealIndex: null, foodIndex: null });
      return;
    }

    // Map for accessing the correct category in swapOptions
    const categoryMap = {
      protein: 'proteins',
      carbs: 'carbs',
      fats: 'fats',
      greenVegetables: 'greenVegetables',
    };

    const categoryKey = categoryMap[food.category];

    setSwapInfo({
      mealIndex,
      foodIndex,
      currentFood: food,
      currentMealType: mealType,
      categoryKey,
    });

    setHighlightedCard({ mealIndex, foodIndex });

    // Scroll the selected food item into view
    if (foodRefs.current[mealIndex] && foodRefs.current[mealIndex][foodIndex]) {
      foodRefs.current[mealIndex][foodIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const shuffleMeal = (mealIndex) => {
    if (!shuffleOptions || !shuffleOptions[mealIndex]) {
      console.error('Shuffle options not initialized.');
      return;
    }
    const plan = customMealPlan.mealPlan[mealIndex];

    // Use the replacementOptions structure for the current meal
    const currentOptions = shuffleOptions[mealIndex];

    // Filter replacement options based on dietType
    const filteredOptions = Object.fromEntries(
      Object.entries(currentOptions).map(([key, foods]) => [
        key,
        foods.filter((food) => food.dietType?.includes(dietType.toLowerCase())),
      ])
    );

    const pairedOptions = generateMealOptions(filteredOptions, plan.name);

    if (!pairedOptions.length) {
      console.error(`No valid paired options for meal index ${mealIndex}`);
      return;
    }

    const selectedOption = pairedOptions[0];

    // Map generateMealOptions keys to database keys
    const categoryMap = {
      protein: 'protein',
      carb: 'carbs',
      fat: 'fats',
      vegetable: 'greenVegetables',
    };

    // Adjust serving sizes for selected foods
    const updatedFoods = Object.keys(categoryMap)
      .map((categoryKey) => {
        const databaseCategory = categoryMap[categoryKey];

        const newFood = selectedOption[categoryKey];
        if (!newFood) return null;

        const currentFood = plan.foods.find(
          (food) => food.category === databaseCategory
        );

        const newServingSize = calculateNewServing(currentFood, newFood);

        return {
          ...newFood,
          category: databaseCategory,
          servingSize: newServingSize,
          macros: {
            calories: Math.round(
              newServingSize * (newFood.macros.calories / newFood.servingSize)
            ),
            protein: Math.round(
              newServingSize * (newFood.macros.protein / newFood.servingSize)
            ),
            carbs: Math.round(
              newServingSize * (newFood.macros.carbs / newFood.servingSize)
            ),
            fats: Math.round(
              newServingSize * (newFood.macros.fats / newFood.servingSize)
            ),
          },
        };
      })
      .filter(Boolean); // Remove null values

    // Update meal plan with shuffled foods
    const updatedMealPlan = [...customMealPlan.mealPlan];
    updatedMealPlan[mealIndex] = {
      ...plan,
      foods: updatedFoods,
      macros: updatedFoods.reduce(
        (acc, food) => {
          acc.calories += food.macros.calories;
          acc.protein += food.macros.protein;
          acc.carbs += food.macros.carbs;
          acc.fats += food.macros.fats;
          return acc;
        },
        { calories: 0, protein: 0, carbs: 0, fats: 0 }
      ),
    };

    setCustomMealPlan({ ...customMealPlan, mealPlan: updatedMealPlan });
  };

  const calculateNewServing = (currentFood, newFood) => {
    const safeDivide = (a, b) => (b && b !== 0 ? a / b : 0);

    // Select the dominant macro or prioritize calories for vegetables
    let dominantMacro;
    switch (newFood.category) {
      case 'protein':
        dominantMacro = 'protein';
        break;
      case 'carbs':
        dominantMacro = 'carbs';
        break;
      case 'fats':
        dominantMacro = 'fats';
        break;
      case 'greenVegetables':
        dominantMacro = 'calories'; // Prioritize calories for vegetables
        break;
      default:
        dominantMacro = 'calories'; // Fallback to calories for other cases
    }

    // Calculate the ratio based on the dominant macro
    const ratio = dominantMacro
      ? safeDivide(
          currentFood?.macros[dominantMacro],
          newFood?.macros[dominantMacro]
        )
      : 1;

    // Adjust serving size based on the ratio
    const newServingSize = Math.round(ratio * newFood.servingSize * 2) / 2;

    // Ensure a valid serving size
    return newServingSize > 0 ? newServingSize : 1;
  };

  const filteredOptions =
    (swapInfo &&
      swapOptions?.[swapInfo.categoryKey]
        ?.filter((food) => {
          if (!swapInfo.currentFood) {
            return false;
          }
          const currentMealType = swapInfo.currentMealType;

          // Only include foods recommended for the current meal type
          return (
            food.recommendedFor.includes(currentMealType) &&
            food.name !== swapInfo.currentFood.name
          );
        })
        .map((food) => {
          const newServingSize = calculateNewServing(
            swapInfo.currentFood,
            food
          );

          const adjustedMacros = {
            calories: Math.round(
              newServingSize * (food.macros.calories / food.servingSize)
            ),
            protein: Math.round(
              newServingSize * (food.macros.protein / food.servingSize)
            ),
            carbs: Math.round(
              newServingSize * (food.macros.carbs / food.servingSize)
            ),
            fats: Math.round(
              newServingSize * (food.macros.fats / food.servingSize)
            ),
          };

          return {
            ...food,
            servingSize: newServingSize,
            macros: adjustedMacros,
          };
        })) ||
    [];

  const displayedOptions = auth.isAuthenticated
    ? filteredOptions
    : filteredOptions.slice(0, 3);

  const handleFoodSwap = (newFood) => {
    const { mealIndex, foodIndex, currentFood } = swapInfo;

    // Calculate the new serving size based on the dominant macro
    const newServingSize = calculateNewServing(currentFood, newFood);

    // Adjust macros for the new food based on the new serving size
    const adjustedMacros = {
      calories: Math.round(
        newServingSize * (newFood.macros.calories / newFood.servingSize)
      ),
      protein: Math.round(
        newServingSize * (newFood.macros.protein / newFood.servingSize)
      ),
      carbs: Math.round(
        newServingSize * (newFood.macros.carbs / newFood.servingSize)
      ),
      fats: Math.round(
        newServingSize * (newFood.macros.fats / newFood.servingSize)
      ),
    };

    // Validate macros to avoid invalid or infinite values
    if (
      Object.values(adjustedMacros).some(
        (value) => isNaN(value) || value === Infinity || value < 0
      )
    ) {
      console.error('Invalid adjusted macros:', adjustedMacros);
      return;
    }

    // Update the swapped food item
    const updatedFood = {
      ...newFood,
      servingSize: newServingSize,
      macros: adjustedMacros,
    };

    // Clone and update the meal plan
    const updatedMealPlan = [...customMealPlan.mealPlan];
    updatedMealPlan[mealIndex].foods[foodIndex] = updatedFood;

    // Recalculate the total macros for the updated meal
    const updatedMealMacros = updatedMealPlan[mealIndex].foods.reduce(
      (acc, food) => {
        acc.calories += food.macros.calories;
        acc.protein += food.macros.protein;
        acc.carbs += food.macros.carbs;
        acc.fats += food.macros.fats;
        return acc;
      },
      { calories: 0, protein: 0, carbs: 0, fats: 0 }
    );

    // Assign recalculated macros to the updated meal
    updatedMealPlan[mealIndex].macros = updatedMealMacros;

    // Update the state with the updated meal plan
    setCustomMealPlan({ ...customMealPlan, mealPlan: updatedMealPlan });

    // Set the highlighted card to show the fade effect
    setHighlightedCard({ mealIndex, foodIndex });

    setTimeout(() => {
      setHighlightedCard({ mealIndex: null, foodIndex: null });
    }, 1000);

    setSwapInfo(null);
  };

  const handleDownloadImage = async () => {
    if (mealPlanRef.current === null) {
      return;
    }

    const isInstagramBrowser = navigator.userAgent.includes('Instagram');
    if (isInstagramBrowser) {
      alert(
        'For the best experience, please open this page in your default browser to download the meal plan.'
      );

      return;
    }

    setLoading(true);

    try {
      // Create a wrapper with padding and consistent styling
      const wrapper = document.createElement('div');
      wrapper.style.padding = '20px'; // Padding at top and bottom
      wrapper.style.backgroundColor = '#000'; // Black background
      wrapper.style.color = '#fff'; // Ensure text color is visible
      wrapper.style.boxSizing = 'border-box'; // Include padding in size
      wrapper.style.display = 'inline-block'; // Ensure proper inline rendering
      wrapper.style.width = `${mealPlanRef.current.offsetWidth}px`;
      wrapper.style.height = `${mealPlanRef.current.offsetHeight + 40}px`; // Add padding (20px top + 20px bottom)

      // Clone the meal plan content and append it to the wrapper
      const clonedContent = mealPlanRef.current.cloneNode(true);
      wrapper.appendChild(clonedContent);

      // Append the wrapper to the document body temporarily
      document.body.appendChild(wrapper);

      // Generate the image from the wrapper
      const dataUrl = await toPng(wrapper, { quality: 1.0 });

      // Remove the wrapper after generating the image
      document.body.removeChild(wrapper);

      // Create a download link
      const link = document.createElement('a');
      link.download = 'custom-meal-plan.png';
      link.href = dataUrl;
      link.click();
    } catch (error) {
      console.error('Error generating image:', error);
    } finally {
      setLoading(false);
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const swapOptionsVariants = {
    hidden: { opacity: 0, height: 0, overflow: 'hidden' },
    visible: { opacity: 1, height: 'auto', overflow: 'hidden' },
    exit: {
      opacity: 0,
      height: 0,
      overflow: 'hidden',
      transition: { duration: 0.8 },
    },
  };

  const renderMealPlan = () => {
    return (
      <div>
        <motion.div
          className='global-card dark'
          style={{
            position: 'sticky',
            top: `${paddingTop}px`,
            zIndex: 10,
            padding: `${isMobileView ? '18px 10px' : '18px'}`,
            borderRadius: '10px',
            border: '1px solid #f9f9f9',
            background:
              'linear-gradient(90deg, rgba(39, 97, 241, 0.5), rgba(255, 0, 162, 0.5))',
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div>
            <div className='flex space-between'>
              <div className='grid justify-center'>
                <p className={`macro-number bold ${macroClasses.calories}`}>
                  {totalMacros.calories}
                </p>
                <p className='subtitle'>🔥 Calories</p>
              </div>
              <div className='grid justify-center'>
                <p className={`macro-number bold ${macroClasses.protein}`}>
                  {totalMacros.protein}g
                </p>
                <p className='subtitle'>💪 Protein</p>
              </div>
              <div className='grid justify-center'>
                <p className={`macro-number bold ${macroClasses.carbs}`}>
                  {totalMacros.carbs}g
                </p>
                <p className='subtitle'>🍎 Carbs</p>
              </div>
              <div className='grid justify-center'>
                <p className={`macro-number bold ${macroClasses.fats}`}>
                  {totalMacros.fats}g
                </p>
                <p className='subtitle'>🥑 Fat</p>
              </div>
            </div>
          </div>
        </motion.div>

        {customMealPlan.mealPlan.map((plan, index) => (
          <motion.div
            className='global-card dark mt-10'
            style={{
              padding: '19px',
            }}
            key={index}
            variants={cardVariants}
            initial='hidden'
            animate='visible'
          >
            <div className='flex align-center space-between mb-20'>
              <p className='eyebrow-text white' style={{ fontSize: '16px' }}>
                {capitalize(plan.name)}
              </p>
              <div
                className='magic-shuffle-button flex align-center'
                onClick={() => shuffleMeal(index)}
              >
                <IoIosShuffle className='mr-5' />
                <p style={{ fontSize: '14px', color: 'white' }}>
                  Magic Shuffle
                </p>
              </div>
            </div>

            <div className='macro-meal-labels'>
              {Object.entries(plan.macros).map((macro) => (
                <div className='flex yellow-label'>
                  <p
                    style={{ fontSize: '14px', fontWeight: '600' }}
                    className='mr-5'
                  >
                    {macro[1]}
                    {`${macro[0] !== 'calories' ? 'g' : ''}`}
                  </p>
                  <p style={{ fontSize: '14px', fontWeight: '600' }}>
                    {capitalize(macro[0])}
                  </p>
                </div>
              ))}
            </div>
            {plan.foods.map((food, foodIndex) => {
              if (!foodRefs.current[index]) {
                foodRefs.current[index] = [];
              }
              return (
                <div
                  ref={(el) => (foodRefs.current[index][foodIndex] = el)}
                  className='food-container'
                  key={foodIndex}
                  style={{
                    marginBottom: '5px',
                    borderRadius: '0 10px 10px 0',
                    backgroundColor:
                      highlightedCard.mealIndex === index &&
                      highlightedCard.foodIndex === foodIndex
                        ? '#2e2e2e'
                        : 'transparent',
                    transition: 'background-color 0.5s ease-in-out',
                  }}
                >
                  <div className='food-item'>
                    <div
                      className='flex align-center'
                      style={{ margin: '5px 0 5px 0' }}
                    >
                      <img
                        className='food-img mr-10'
                        alt={food.name}
                        src={food.img}
                      />
                      <div>
                        <p className='subtitle bold'>
                          {food.servingSize} {food.servingType} - {food.name}
                        </p>
                        <p className='subtitle' style={{ fontSize: '16px' }}>
                          {food.macros.calories} Calories |{' '}
                          {food.macros.protein} Protein | {food.macros.carbs}{' '}
                          Carbs | {food.macros.fats} Fats
                        </p>
                      </div>
                    </div>
                    <div
                      className='swap-button flex align-center'
                      onClick={() =>
                        handleSwapClick(index, foodIndex, food, plan.name)
                      }
                    >
                      <IoIosSwap className='mr-5' />
                      <p style={{ fontSize: '14px', color: 'white' }}>Swap</p>
                    </div>
                  </div>
                  {/* Render swap options directly under the specific food */}
                  {swapInfo && (
                    <AnimatePresence>
                      {swapInfo.mealIndex === index &&
                        swapInfo.foodIndex === foodIndex && (
                          <motion.div
                            className='swap-options gradient-border-left'
                            variants={swapOptionsVariants}
                            initial='hidden'
                            animate='visible'
                            exit='exit'
                            style={{
                              marginTop: '10px',
                              padding: '0px 10px',
                              position: 'relative',
                            }}
                          >
                            <p
                              className='text-gradient'
                              style={{
                                fontSize: '18px',
                                fontWeight: 'bold',
                                marginBottom: '10px',
                                color: '#fff',
                              }}
                            >
                              Swap For
                            </p>
                            {displayedOptions.map((option) => {
                              const newServingSize = calculateNewServing(
                                swapInfo.currentFood,
                                option
                              );

                              const adjustedMacros = {
                                calories: Math.round(
                                  newServingSize *
                                    (option.macros.calories /
                                      option.servingSize)
                                ),
                                protein: Math.round(
                                  newServingSize *
                                    (option.macros.protein / option.servingSize)
                                ),
                                carbs: Math.round(
                                  newServingSize *
                                    (option.macros.carbs / option.servingSize)
                                ),
                                fats: Math.round(
                                  newServingSize *
                                    (option.macros.fats / option.servingSize)
                                ),
                              };

                              // Skip invalid options
                              if (
                                newServingSize <= 0 ||
                                Object.values(adjustedMacros).some(
                                  (value) => value < 0
                                )
                              ) {
                                return null;
                              }

                              return (
                                <div
                                  key={option.name}
                                  style={{
                                    borderBottom: '1px solid #ccc',
                                    marginBottom: '8px',
                                    paddingBottom: '8px',
                                    color: '#fff',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <span>
                                      {option.name} ({newServingSize}{' '}
                                      {option.servingType})
                                    </span>
                                    <p
                                      style={{
                                        color: '#ff00a2',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <span
                                        onClick={() => handleFoodSwap(option)}
                                      >
                                        Select
                                      </span>
                                    </p>
                                  </div>
                                  <div style={{ fontSize: '14px' }}>
                                    {adjustedMacros.calories} Calories |{' '}
                                    {adjustedMacros.protein}g Protein |{' '}
                                    {adjustedMacros.carbs}g Carbs |{' '}
                                    {adjustedMacros.fats}g Fats
                                  </div>
                                </div>
                              );
                            })}
                            {!auth.isAuthenticated && (
                              <div
                                style={{
                                  marginBottom: '8px',
                                  paddingBottom: '8px',
                                  fontWeight: '600',
                                  fontSize: '16px',
                                }}
                              >
                                <p className='subtitle mb-05'>
                                  ✨ Unlock All Food Options!
                                </p>
                                <p
                                  className='subtitle'
                                  style={{
                                    cursor: 'pointer',
                                    fontWeight: '600',
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    dispatch({
                                      type: 'OPEN_AUTH_MODAL',
                                      payload: true,
                                    });
                                  }}
                                >
                                  🎉 Create Your Free Account
                                </p>
                              </div>
                            )}
                          </motion.div>
                        )}
                    </AnimatePresence>
                  )}
                </div>
              );
            })}
          </motion.div>
        ))}
      </div>
    );
  };

  return (
    <div className='landing-page black-background'>
      <div className='results-container'>
        <>
          <Modal
            className='generate-meal-plan-modal dark'
            open={shoppingListModalOpen}
            onCancel={() => setShoppingListModal(false)}
            footer={null}
            centered
            width={450}
            closable={true}
          >
            <p className='subtitle white mb-30 mt-10'>
              Shop for{' '}
              <span className='small-title' style={{ color: '#dcdcdc' }}>
                {shoppingSliderValue}
              </span>{' '}
              days.
            </p>
            <Slider
              onChange={(value) => setshoppingSliderValue(value)}
              range={false}
              min={1}
              max={10}
              tooltip={{ open: false }}
              value={shoppingSliderValue}
              className='shopping-slider mb-30'
            />
            <Button
              type='primary'
              onClick={() => shareShoppingList(mealPlan, shoppingSliderValue)}
            >
              <div className='flex align-center'>
                <BsCopy className='mr-5' />
                Create And Copy To Clipboard
              </div>
            </Button>
          </Modal>
          <motion.div>
            <div className='flex align-center space-between mb-20'>
              <p className={`small-title text-center text-gradient `}>
                {`${
                  auth.isAuthenticated && auth.user?.name
                    ? `${auth.user.name}${
                        auth.user.name.endsWith('s') ? "'" : "'s"
                      }`
                    : 'Your'
                } Meal Plan`}
              </p>
              <div className='flex align-center' style={{ cursor: 'pointer' }}>
                <IoIosArrowBack
                  className='button-icon'
                  style={{ color: 'white', marginRight: '3px' }}
                />
                <p
                  className='subtitle white '
                  onClick={() => {
                    dispatch({
                      type: 'HANDLE_GENERATE_MEALPLAN_MODAL',
                      payload: false,
                    });
                    navigate(
                      isAdvancedMode
                        ? '/tools/macronutrient-calculator'
                        : '/tools/macronutrient-calculator/results',
                      {
                        state: {
                          isAdvancedMode: isAdvancedMode || false,
                        },
                      }
                    );
                  }}
                >
                  Back
                </p>
              </div>
            </div>

            {!isMobileView && (
              <div
                className='flex align-center mb-20'
                style={{ cursor: 'pointer', gap: '10px' }}
              >
                <Button
                  type='primary'
                  className='flex align-center'
                  onClick={handleDownloadImage}
                  disabled={loading}
                  loading={loading}
                  style={{
                    border: '1px solid white',
                    background: 'black',
                    borderRadius: '10px',
                  }}
                  icon={<BsDownload className='mr-10' />}
                >
                  <p
                    style={{
                      color: 'white',
                      fontSize: '16px',
                      fontWeight: '600',
                    }}
                  >
                    Download Meal Plan as Image
                  </p>
                </Button>
                <Button
                  className='flex align-center'
                  icon={<PiShareFatBold className='mr-10' />}
                  type='primary'
                  onClick={() =>
                    auth.isAuthenticated
                      ? setShoppingListModal(true)
                      : dispatch({ type: 'OPEN_AUTH_MODAL', payload: true })
                  }
                  style={{ border: '1px solid white', background: 'black' }}
                >
                  Share Shopping List
                </Button>

                {!auth.isAuthenticated && (
                  <Button
                    type='primary'
                    onClick={() =>
                      dispatch({ type: 'OPEN_AUTH_MODAL', payload: true })
                    }
                  >
                    🎉 Create Your Free Account!
                  </Button>
                )}
              </div>
            )}
          </motion.div>
        </>

        <div ref={mealPlanRef}>{renderMealPlan()}</div>
        <div className='global-card dark mt-10'>
          <p className='subtitle'>Did you find this meal plan useful?</p>
          <div className='mt-20'>
            {isMobileView && (
              <>
                <Button
                  type='primary'
                  className='flex align-center landing-button'
                  onClick={handleDownloadImage}
                  disabled={loading}
                  loading={loading}
                  style={{ border: '1px solid white', background: 'black' }}
                  icon={<BsDownload className='mr-10' />}
                >
                  <p
                    style={{
                      color: 'white',
                      fontSize: '16px',
                      fontWeight: '600',
                    }}
                  >
                    Download Meal Plan as Image
                  </p>
                </Button>
                {auth.isAuthenticated && (
                  <Button
                    className='flex align-center mt-10'
                    icon={<PiShareFatBold className='mr-10' />}
                    type='primary'
                    onClick={() => setShoppingListModal(true)}
                    style={{ border: '1px solid white', background: 'black' }}
                  >
                    Share Shopping List
                  </Button>
                )}
                {!auth.isAuthenticated && (
                  <Button
                    type='primary'
                    className='flex align-center mt-10'
                    onClick={() =>
                      dispatch({ type: 'OPEN_AUTH_MODAL', payload: true })
                    }
                  >
                    🎉 Create Your Free Account
                  </Button>
                )}
              </>
            )}
            <Button
              className='flex align-center mt-10'
              onClick={() =>
                window.open('https://buymeacoffee.com/alvarofitness', '_blank')
              }
              style={{
                borderRadius: '10px',
                border: 'none',
                height: '50px',
              }}
            >
              <p
                style={{
                  color: 'black',
                  fontSize: '16px',
                  fontWeight: '600',
                }}
              >
                ☕️ Support This Project
              </p>
            </Button>
          </div>
          <div className='mt-20'>
            <p className='subtitle mb-10'>Have Feedback?</p>
            <p className='subtitle'>
              <span
                onClick={() =>
                  window.open('https://forms.gle/oUuntpUFzboqfLSz7', '_blank')
                }
                style={{ color: '#2761f1', cursor: 'pointer' }}
              >
                💬 Provide Feedback
              </span>
            </p>
            <p className=' subtitle'>
              <span
                onClick={() =>
                  window.open('https://forms.gle/gx7RDnexcZzzR7pt6', '_blank')
                }
                style={{ color: '#2761f1', cursor: 'pointer' }}
              >
                🥗 Request to add a food
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
