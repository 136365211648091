import React from 'react';
import { Button, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
// import LanguageSwitcher from '../LanguageSwitcher';
import './index.css';
import { AdvancedMode } from './advancedMode';
import { GenerateMealplanModal } from './generateMealplanModal';
import { useDispatch, useSelector } from 'react-redux';

export const MacronutrientCalculatorComponent = ({
  setIsAdvancedMode,
  isAdvancedMode,
  handleDropdownValue,
  macronutrientAnswers,
  renderDropdown,
  questionOptions,
  renderInputField,
  handleCalculateAndShowResults,
  errors,
  heightOptions,
  isMobileView,
}) => {
  const { t } = useTranslation();
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const dispatch = useDispatch();

  const metricOptions = [
    {
      label: 'Imperial',
      value: 'imperial',
    },
    {
      label: 'Metric',
      value: 'metric',
    },
  ];

  return (
    <div
      className='global-card dark'
      style={{ padding: '0px', border: 'none' }}
    >
      <GenerateMealplanModal
        isAdvancedMode={isAdvancedMode}
        isMobileView={isMobileView}
      />
      <div className='flex'>
        <Button
          className='landing-button gradient'
          type='primary'
          style={{
            height: 'auto',
            justifySelf: 'flex-end',
            borderRadius: '0px 10px 0px 10px',
            width: '100%',
            minWidth: '250px',
          }}
          onClick={() =>
            isAuthenticated
              ? setIsAdvancedMode((prevMode) => !prevMode)
              : dispatch({ type: 'OPEN_AUTH_MODAL', payload: true })
          }
        >
          ⚙️{' '}
          {isAdvancedMode
            ? 'Switch to Standard Mode'
            : 'Switch to Advanced Mode'}{' '}
        </Button>
      </div>
      {!isAdvancedMode ? (
        <div className='questions-container'>
          {/* <LanguageSwitcher /> */}
          <Radio.Group
            className='mb-20'
            options={metricOptions}
            onChange={(e) => handleDropdownValue('unit', e.target.value)}
            optionType='button'
            buttonStyle='solid'
            defaultValue={macronutrientAnswers.unit}
          />
          <div className='two-col-input'>
            {renderDropdown(
              questionOptions(t).gender.options,
              questionOptions(t).gender.name,
              'gender',
              questionOptions(t).gender.placeholder
            )}
            {renderInputField(
              questionOptions(t).age.name,
              'age',
              questionOptions(t).age.placeholder
            )}
          </div>
          <div className='two-col-input'>
            {renderInputField(
              questionOptions(t).weight.name,
              'weight',
              questionOptions(t).weight.placeholder
            )}
            {macronutrientAnswers.unit === 'imperial'
              ? renderDropdown(
                  questionOptions(t, heightOptions).height.options,
                  questionOptions(t).height.name,
                  'height',
                  questionOptions(t).height.placeholder
                )
              : renderInputField(
                  questionOptions(t).height.name,
                  'height',
                  questionOptions(t).height.placeholder
                )}
          </div>
          <div className='two-col-input'>
            {renderDropdown(
              questionOptions(t).activityFactor.options,
              questionOptions(t).activityFactor.name,
              'activityFactor',
              questionOptions(t).activityFactor.placeholder
            )}
            <div>
              {renderDropdown(
                questionOptions(t).bmrFormula.options,
                questionOptions(t).bmrFormula.name,
                'bmrFormula',
                questionOptions(t).bmrFormula.placeholder
              )}
              {macronutrientAnswers.bmrFormula === 'katch-mcardle'
                ? renderInputField(
                    t('questions.bodyFatPercentage.label'),
                    'bodyFatPercentage'
                  )
                : null}
            </div>
          </div>

          {renderDropdown(
            questionOptions(t).goal.options,
            questionOptions(t).goal.name,
            'goal',
            questionOptions(t).goal.placeholder
          )}

          <Button
            className='custom-cursor'
            onClick={() => handleCalculateAndShowResults()}
            size='large'
            type='primary'
          >
            {t('buttons.getMyResults')}
          </Button>
          {Object.keys(errors).length > 0 && (
            <div className='error-messages mt-20'>
              {Object.entries(errors).map(([field, message]) => (
                <p key={field} className='error-text'>
                  {message}
                </p>
              ))}
            </div>
          )}
        </div>
      ) : (
        <AdvancedMode isAdvancedMode={isAdvancedMode} />
      )}
    </div>
  );
};
