import React, { useEffect, useState } from 'react';
import { Slider, Button, Input } from 'antd';
import { capitalize } from '../../helpers/utils';
import { useDispatch, useSelector } from 'react-redux';

export const AdvancedMode = () => {
  const dispatch = useDispatch();
  const advancedModeMacros = useSelector(
    (state) => state.global.advancedModeMacros
  );
  const [isComplete, setIsComplete] = useState(true);
  const [percentages, setPercentages] = useState({
    protein: advancedModeMacros.proteinPercentage,
    carbs: advancedModeMacros.carbPercentage,
    fat: advancedModeMacros.fatPercentage,
  });

  const [macroValues, setMacroValues] = useState({
    calories: advancedModeMacros.calories,
    protein: advancedModeMacros.protein,
    carbs: advancedModeMacros.carbs,
    fat: advancedModeMacros.fat,
  });

  const updateGlobalState = () => {
    dispatch({
      type: 'SET_ADVANCED_MODE_MACROS',
      payload: {
        calories: Math.round(macroValues.calories),
        protein: Math.round(macroValues.protein),
        carbs: Math.round(macroValues.carbs),
        fat: Math.round(macroValues.fat),
        proteinPercentage: percentages.protein,
        carbPercentage: percentages.carbs,
        fatPercentage: percentages.fat,
      },
    });
  };

  useEffect(() => {
    updateGlobalState();
  }, [percentages, macroValues]);

  const checkCompletion = () => {
    const totalPercentage = Math.round(
      percentages.protein + percentages.carbs + percentages.fat
    );

    setIsComplete(totalPercentage === 100); // Ensure rounded values sum to 100
  };

  useEffect(() => {
    const { protein, carbs, fat } = percentages;
    const { calories } = macroValues;

    setMacroValues((prev) => ({
      ...prev,
      protein: (calories * protein) / 100 / 4,
      carbs: (calories * carbs) / 100 / 4,
      fat: (calories * fat) / 100 / 9,
    }));
  }, [percentages, macroValues.calories]);

  const handleInputChange = (name, value) => {
    const inputValue = Math.max(0, Number(value));

    if (name === 'calories') {
      setMacroValues((prev) => ({
        ...prev,
        calories: inputValue,
      }));
    } else {
      setPercentages((prev) => ({
        ...prev,
        [name]: inputValue,
      }));
    }

    if (name !== 'calories') {
      const totalPercentage =
        name === 'protein'
          ? inputValue + percentages.carbs + percentages.fat
          : name === 'carbs'
          ? percentages.protein + inputValue + percentages.fat
          : name === 'fat'
          ? percentages.protein + percentages.carbs + inputValue
          : percentages.protein + percentages.carbs + percentages.fat;

      const tolerance = 0.01; // Allow small rounding errors
      setIsComplete(Math.abs(totalPercentage - 100) <= tolerance);
    }
  };

  const renderInputs = () => {
    return ['calories', 'protein', 'carbs', 'fat'].map((name) => {
      return (
        <div key={name} className='mb-20'>
          <p className='label white'>{capitalize(name)}</p>
          <Input
            type='number'
            value={
              name === 'calories'
                ? macroValues.calories
                : Math.round(percentages[name])
            }
            addonAfter={name !== 'calories' ? '%' : ''}
            placeholder={name}
            onChange={(e) => handleInputChange(name, e.target.value)}
          />
        </div>
      );
    });
  };

  const handleSliderChange = (name, value) => {
    setMacroValues((prev) => {
      const { calories, protein, carbs, fat } = prev;

      // Handle calories adjustment
      if (name === 'calories') {
        const totalCalories = protein * 4 + carbs * 4 + fat * 9;

        // Calculate ratios based on current macros to distribute proportionally
        const proteinPercentage = (protein * 4) / totalCalories;
        const carbPercentage = (carbs * 4) / totalCalories;
        const fatPercentage = (fat * 9) / totalCalories;

        // Calculate new macros
        const newProtein = (value * proteinPercentage) / 4;
        const newCarbs = (value * carbPercentage) / 4;
        const newFat = (value * fatPercentage) / 9;

        // Adjust macros based on the new calorie value
        return {
          ...prev,
          calories: value,
          protein: newProtein,
          carbs: newCarbs,
          fat: newFat,
        };
      }

      // Handle protein adjustment
      if (name === 'protein') {
        const proteinPercentage = (value * 4 * 100) / calories;
        const remainder = 100 - proteinPercentage;

        // Distribute the remainder proportionally between carbs and fats
        const carbsToFatRatio =
          percentages.carbs / (percentages.carbs + percentages.fat || 1); // Avoid division by 0
        const newCarbsPercentage = remainder * carbsToFatRatio;
        const newFatPercentage = remainder * (1 - carbsToFatRatio);

        // Update percentages state
        setPercentages((prevPercentages) => ({
          ...prevPercentages,
          protein: proteinPercentage,
          carbs: newCarbsPercentage,
          fat: newFatPercentage,
        }));

        return {
          ...prev,
          protein: value,
          carbs: Math.round((calories * newCarbsPercentage) / 100 / 4),
          fat: Math.round((calories * newFatPercentage) / 100 / 9),
        };
      }

      // Handle carbs adjustment
      if (name === 'carbs') {
        const newCarbCalories = value * 4;
        const remainingCalories = Math.max(
          0,
          calories - protein * 4 - newCarbCalories
        );
        const newFatValue = Math.round(remainingCalories / 9);

        const newCarbsPercentage = Math.round(
          (newCarbCalories / calories) * 100
        ); // Carbs percentage
        const newFatPercentage = Math.round(
          (remainingCalories / calories) * 100
        );

        setPercentages((prevPercentages) => ({
          ...prevPercentages,
          carbs: newCarbsPercentage,
          fat: newFatPercentage,
        }));

        return {
          ...prev,
          carbs: value,
          fat: newFatValue,
        };
      }

      if (name === 'fat') {
        // Adjust fat and recalculate carbs, update percentages
        const newFatCalories = value * 9;
        const remainingCalories = Math.max(
          0,
          calories - protein * 4 - newFatCalories
        );
        const newCarbValue = Math.round(remainingCalories / 4);

        const newFatPercentage = Math.round((newFatCalories / calories) * 100);
        const newCarbsPercentage = Math.round(
          (remainingCalories / calories) * 100
        );

        setPercentages((prevPercentages) => ({
          ...prevPercentages,
          fat: newFatPercentage,
          carbs: newCarbsPercentage,
        }));

        return {
          ...prev,
          fat: value,
          carbs: newCarbValue,
        };
      }

      return prev;
    });
  };

  return (
    <div style={{ padding: '30px' }}>
      <div className='custom-macros-input-container'>{renderInputs()}</div>
      <div>
        {['calories', 'protein', 'carbs', 'fat'].map((name) => (
          <div key={name} className='grid'>
            <div className='grid justify-center'>
              <p className='small-title'>
                {name !== 'calories'
                  ? `${Math.round(macroValues[name])}g`
                  : Math.round(macroValues[name])}
              </p>
              <p
                className='label white'
                style={{
                  marginBottom: '5px',
                  lineHeight: '22px',
                }}
              >
                {capitalize(name)}
              </p>
            </div>
            <Slider
              className={`shopping-slider slider-${name} mb-30`}
              onChange={(value) => handleSliderChange(name, value)}
              onAfterChange={() => checkCompletion()}
              min={name === 'calories' ? 1000 : 0}
              max={
                name === 'calories'
                  ? 5000
                  : Math.floor(macroValues.calories / (name === 'fat' ? 9 : 4))
              }
              value={macroValues[name]}
              tooltip={{ open: false }}
              step={1}
            />
          </div>
        ))}
      </div>
      <Button
        type='primary'
        size='large'
        className='landing-button full-width-button gradient custom-cursor'
        style={{
          height: '80px',
          padding: '0 40px',
          fontSize: '20px',
        }}
        disabled={!isComplete}
        onClick={() =>
          dispatch({ type: 'HANDLE_GENERATE_MEALPLAN_MODAL', payload: true })
        }
      >
        Generate Your Meal Plan
      </Button>

      {!isComplete && (
        <p className='text-center mt-20' style={{ color: 'red' }}>
          Sum of protein, carbs, and fat percentages must be 100%.
        </p>
      )}
    </div>
  );
};
