import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../ducks/Auth/actions';
import {
  handleGlobalModal,
  saveMacronutrientAnswers,
} from '../ducks/Global/actions';
import { disclaimer, privacyPolicy } from '../data/legal';
import { Input, Space, Button, Divider } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import AlvaroFitness from '../assets/images/af-logo-white.png';
import { mealPlanFeatures } from '../data/features';
import '../index.css';

export const Register = ({ isModal, isRegister }) => {
  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formFields, setFormFields] = useState({
    name: '',
    lastName: '',
    email: '',
    password: '',
  });
  const auth = useSelector((state) => state.auth);
  const isLogin = useSelector((state) => state.auth.isLogin);
  const serverErrors = useSelector((state) => state.auth.errors);
  const location = useLocation();
  const navigate = useNavigate();
  const isMobileView = useSelector((state) => state.global.isMobileView);
  const macronutrientAnswers = useSelector(
    (state) => state.global.macronutrientAnswers
  );
  const registrationLoading = useSelector(
    (state) => state.auth.registrationLoading
  );

  const onChange = (val, e) => {
    setFormFields({ ...formFields, [val]: e.target.value });
  };

  useEffect(() => {
    if (auth.isAuthenticated && auth.user) {
      if (location.pathname.includes('macronutrient-calculator')) {
        dispatch({ type: 'OPEN_AUTH_MODAL', payload: false });
      } else {
        navigate('/'); // Default redirect for non-admin users
      }
    }
  }, [auth.isAuthenticated, auth.user, location.pathname, dispatch, navigate]);

  useEffect(() => {
    if (auth.isAuthenticated && auth.user && isModal) {
      dispatch(saveMacronutrientAnswers(macronutrientAnswers));
      dispatch({ type: 'HANDLE_WELCOME_MODAL', payload: true });
    }
  }, [dispatch, auth.isAuthenticated, auth.user, isModal]);

  const handleRegister = async () => {
    dispatch(register(formFields));
  };

  return (
    <div
      className={`auth-page `}
      style={{
        borderRadius: isModal ? '10px' : '0px',
        height: !isModal && !isMobileView ? '100vh' : '',
      }}
    >
      <div
        className={`auth-container ${isModal ? 'modal' : ''} ${
          isRegister ? 'register' : ''
        }`}
      >
        <div>
          <div className='mb-30'>
            <h3
              style={{
                color: 'white',
                textAlign: 'center',
                marginBottom: '10px',
              }}
              className='white'
            >
              🎉 Create Your Free Account!
            </h3>
            <p style={{ color: '#ccc', textAlign: 'center', fontSize: '16px' }}>
              Unlock tools, features, and more!
            </p>
          </div>

          {mealPlanFeatures.map((feature, index) => (
            <p key={index} style={{ fontSize: '16px', margin: '10px 0' }}>
              <div className='flex mb-10'>
                <p
                  style={{
                    marginRight: '8px',
                    marginTop: '2px',
                  }}
                >
                  {feature.icon}
                </p>
                <p>{feature.text}</p>
              </div>
            </p>
          ))}
        </div>
        {isMobileView && (
          <Divider style={{ background: 'white', margin: '10px 0px' }} />
        )}
        <div className='grid justify-center'>
          <img
            className='mb-20'
            src={AlvaroFitness}
            alt='logo'
            style={{ height: '35px', cursor: 'pointer' }}
            onClick={() => navigate('/')}
          />
          <Space direction='vertical'>
            <Input
              className='register-input'
              placeholder='First Name'
              onChange={(e) => onChange('name', e)}
            />
            <Input
              className='register-input'
              placeholder='Last Name'
              onChange={(e) => onChange('lastName', e)}
            />
            <Input
              className='register-input'
              placeholder='Email address'
              onChange={(e) => onChange('email', e)}
            />
            <Input.Password
              className='register-input'
              onChange={(e) => onChange('password', e)}
              placeholder='Password'
              iconRender={(visible) =>
                visible ? (
                  <EyeTwoTone
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  />
                ) : (
                  <EyeInvisibleOutlined
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  />
                )
              }
              maxLength={20}
            />
            <Button
              style={{ width: '100%', borderRadius: '5px' }}
              onClick={() => handleRegister(formFields)}
              type='primary'
              loading={registrationLoading}
            >
              Create My Account Now!
            </Button>
            <p
              className='mt-10 text-center'
              style={{ fontSize: '12px', color: '#909090' }}
            >
              By signing up, you accept our{' '}
              <span
                onClick={() => dispatch(handleGlobalModal(true, disclaimer))}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                disclaimer
              </span>{' '}
              and{' '}
              <span
                onClick={() => dispatch(handleGlobalModal(true, privacyPolicy))}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                privacy policy
              </span>
              .
            </p>
            <div className='flex center-aligned justify-center mt-10'>
              <p style={{ fontSize: '16px' }} className='mr-5'>
                Already have an account?
              </p>
              <Link
                onClick={() => {
                  dispatch({ type: 'CLEAR_AUTH_ERROR' });
                  dispatch({ type: 'SET_IS_LOGIN', payload: !isLogin });
                }}
                style={{ fontSize: '16px' }}
                to={!isModal && '/login'}
              >
                Log in
              </Link>
            </div>
            {serverErrors &&
              serverErrors.map((err, index) => (
                <p
                  key={index}
                  className='text-center'
                  style={{ color: 'red', marginTop: '10px' }}
                >
                  {err.msg}
                </p>
              ))}
          </Space>
        </div>
      </div>
    </div>
  );
};
